<!-- 富文本h5页面 -->
<template>
<div class="ql-container ql-snow" style="border:none;padding:6px;" id="docpage">
    <div class="ql-editor">
      <doc></doc>
    </div>

</div>
  
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import Doc from './doc.vue'
export default {
  data () {
    return {
    };
  },
  components: {
    Doc
  },
  computed: {},
  methods: {},
  created(){
  },
  mounted(){
    document.body.style.minWidth='auto';
  },
  destroyed(){
    document.body.style.minWidth='1200px';
  }

}

</script>
<style lang='scss' scoped>
</style>