<!--  -->
<template>
  <div>
    <div v-html="html"></div>
  </div>
</template>

<script>
import {getDocumentDetail} from '../../api/system'
export default {
  name:'doc',
  data () {
    return {
      id:'',
      html:''
    };
  },
  components: {},
  computed: {},
  methods: {
    getDetail(){
      getDocumentDetail({id:this.id}).then(res=>{
        let data = res.data;
        if(data.code==='000'){
          this.html = data.content.richText;
          document.title = data.content.title;
        }else{
          console.log(data.desc);
        }
      })
    }
  },
  created(){
    this.id = this.$route.query.id;
    this.getDetail();
  }
}

</script>
<style lang='scss' scoped>
</style>